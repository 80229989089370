import { KeyboardEvent, MouseEvent, useRef } from 'react'

import { useMaybeSpaLink } from './useMaybeSpaLink'

type MaybeSpaLinkWrapperComponent = React.FC<{ href: string; children?: React.ReactNode }>

export const MaybeSpaLinkWrapper: MaybeSpaLinkWrapperComponent = ({ href, children }) => {
  const { isSpaLink, spaLinkHandler } = useMaybeSpaLink(href)
  const ref = useRef<HTMLSpanElement | null>(null)

  const keyHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (isSpaLink && event.key === 'Enter') {
      const target = event.target
      if (ref.current && typeof HTMLElement !== 'undefined' && target instanceof HTMLElement) {
        let nextElement: HTMLElement = target
        let href: string | null = null
        while (!href && nextElement !== ref.current && nextElement.parentElement) {
          href = nextElement.getAttribute('href')
          nextElement = nextElement.parentElement
        }
        if (href) {
          event.preventDefault()
          spaLinkHandler()
        }
      }
    }
  }

  const clickHandler = (event: MouseEvent<HTMLElement>) => {
    if (isSpaLink) {
      const target = event.target
      if (ref.current && typeof HTMLElement !== 'undefined' && target instanceof HTMLElement) {
        let nextElement: HTMLElement = target
        let href: string | null = null
        while (!href && nextElement !== ref.current && nextElement.parentElement) {
          href = nextElement.getAttribute('href')
          nextElement = nextElement.parentElement
        }
        if (href) {
          event.preventDefault()
          spaLinkHandler()
        }
      }
    }
  }

  return (
    <span // eslint-disable-line jsx-a11y/no-static-element-interactions
      data-testid='spa-anchor-wrapper'
      onClick={clickHandler}
      onKeyDown={keyHandler}
      style={{ display: 'contents' }}
      ref={ref}
    >
      {children}
    </span>
  )
}
