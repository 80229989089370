import { FC } from 'react'

import { ITrackEvent } from '@syconium/magnolia/src/lib/analytics/IProps'

import { CarouselProductSummary } from '../../../../types/figs'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const ProductCarouselTile: FC<{
  aspectRatio: number
  hideColors?: boolean
  hidePrice?: boolean
  product: CarouselProductSummary
  trackEvent: ITrackEvent
  visibleTilesDesktop: number
  visibleTilesMobile: number
}> = ({
  aspectRatio,
  hideColors,
  hidePrice,
  product,
  trackEvent,
  visibleTilesDesktop,
  visibleTilesMobile,
}) => {
  const viewModel = useViewModel({ colorCount: product.colorCount })
  return (
    <Template
      aspectRatio={aspectRatio}
      hideColors={hideColors}
      hidePrice={hidePrice}
      product={product}
      trackEvent={trackEvent}
      visibleTilesDesktop={visibleTilesDesktop}
      visibleTilesMobile={visibleTilesMobile}
      {...viewModel}
    />
  )
}
