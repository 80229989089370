import { FC } from 'react'

import { Skeleton } from '@syconium/little-miss-figgy'

import { Body, Colors, ImageWrap, PriceDisplayWrap, Title } from '../styles'

import { ImageSkeleton } from './styles'

export const ProductCarouselTileSkeleton: FC<{ aspectRatio: number }> = ({ aspectRatio }) => (
  <Body>
    <ImageWrap aspectRatio={aspectRatio}>
      <ImageSkeleton animated />
    </ImageWrap>
    <Title>
      <Skeleton height='15px' maxWidth='176px' />
    </Title>
    <Colors>
      <Skeleton height='15px' maxWidth='157px' />
    </Colors>
    <PriceDisplayWrap>
      <Skeleton height='15px' maxWidth='107px' />
    </PriceDisplayWrap>
  </Body>
)
