import pluralize from 'pluralize'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

export function useViewModel({ colorCount }: { colorCount: number }) {
  const { magnolia: strings } = useFixturesContext()

  return {
    colorCountText: pluralize(strings.productCarouselColorCount, colorCount),
  }
}
