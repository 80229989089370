import styled, { css } from 'styled-components'

import { TypeStyle, fromMd, pageLayoutMaxWidth, timingSlower } from '@syconium/little-miss-figgy'

import { fadeIn } from '../../styles/shared'

import type { CSSProperties } from 'react'

export const Body = styled.div<{ fadeIn: boolean }>`
  ${o =>
    o.fadeIn &&
    css`
      animation: ${fadeIn} ${timingSlower};
    `};

  margin-left: auto;
  margin-right: auto;
  max-width: ${pageLayoutMaxWidth};
  box-sizing: border-box;

  padding: ${o => o.theme.spacing(5)} ${o => o.theme.spacing(4)};

  ${fromMd} {
    padding: ${o => o.theme.spacing(10)} ${o => o.theme.spacing(6)};
  }
`

export const Title = styled(TypeStyle.HeadlineSmall)<{
  textAlignDesktop?: CSSProperties['textAlign']
}>`
  margin-bottom: 16px;

  ${fromMd} {
    margin-bottom: 24px;
    text-align: ${o => o.textAlignDesktop ?? 'left'};
  }
`
