export enum PageCategory {
  Home = 'home',
  Landing = 'landing',
  Collection = 'collection',
  Product = 'product',
  Search = 'search',
  Not_Found = 'not found',
  Other = 'other',
}

export interface SignalVariant {
  category: string
  color: string
  discountPrice: number | null
  externalParentId: string
  genderCategory: string
  fit?: string
  handle: string
  id: string
  name: string
  price: number
  size: string | null
  sku: string
  soldOut: boolean
  style: string
}

export interface ProductGroupImpressionSignal {
  [key: string]: string | undefined | null | boolean

  handle: string
}

export interface ProductImpressionSignal {
  [key: string]: string | undefined | null | boolean

  featuredProduct: boolean
  handle: string
  id: string
  shopifyId: string
  soldOut: boolean
}

export interface ProductInteractionSignal {
  [key: string]: string | undefined | null | boolean

  handle: string
  id: string
  shopifyId: string
}

export interface PageViewSignal {
  handle: string | undefined
  category: PageCategory
}

export interface CartItem {
  quantity: number
  variantShopifyId: string
  variantSku: string
}

export interface CartModifiedSignal {
  [key: string]: string | CartItem[]

  id: string
  items: CartItem[]
}
