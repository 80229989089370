import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const Body = styled.div``

export const ImageWrap = styled.div<{
  aspectRatio: number
}>`
  padding-top: ${o => (1 / o.aspectRatio) * 100}%;
  position: relative;
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)`
  color: ${o => o.theme.color.text.primary.on.background};
  margin-top: 8px;

  ${fromMd} {
    margin-top: 16px;
  }
`

export const Colors = styled.div`
  ${TypeStyle.css.headlineExtraExtraSmall};
  color: ${o => o.theme.color.text.tertiary.on.background};
  margin-top: 8px;

  ${fromMd} {
    margin-top: 16px;
  }
`

export const ColorName = styled.span`
  margin-right: 12px;
`

export const ColorCount = styled.span`
  font-weight: 500;
`

export const PriceDisplayWrap = styled.div`
  margin-top: 8px;

  ${fromMd} {
    margin-top: 16px;
  }
`
