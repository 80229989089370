import { FC, useMemo } from 'react'

import { tileAspectRatioByLayoutType } from '../../../constants'
import { TileLayout } from '../../TileLayout'
import { ProductCarouselTileSkeleton } from '../ProductCarouselTile/Skeleton'

export type ProductCarouselSkeletonProps = {
  fullBleed?: boolean
  hideSectionSideGaps?: boolean
  visibleTilesDesktop?: number
  visibleTilesMobile?: number
}

export const ProductCarouselSkeleton: FC<ProductCarouselSkeletonProps> = ({
  fullBleed = false,
  hideSectionSideGaps,
  visibleTilesDesktop = 4,
  visibleTilesMobile = 2,
}) => {
  const fakeData = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [])

  return (
    <TileLayout
      fullBleed={fullBleed}
      hideSectionSideGaps={hideSectionSideGaps}
      visibleTilesDesktop={visibleTilesDesktop}
      visibleTilesMobile={visibleTilesMobile}
      keyFromTile={(p, i) => `${p}-${i}`}
      layoutVariantDesktop='carousel'
      layoutVariantMobile='slider'
      liftedButtons
      renderTile={(_data, { layoutVariant }, _index) => {
        return (
          <ProductCarouselTileSkeleton aspectRatio={tileAspectRatioByLayoutType[layoutVariant]} />
        )
      }}
      tiles={fakeData}
    />
  )
}
