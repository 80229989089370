import { useEffect } from 'react'

import { useSignalTracking } from '../../../../containers/SignalContainer'
import { PageViewSignal } from '../../../../types/signals'

export function useTrackPageView({ handle, category }: PageViewSignal) {
  const { trackPageView } = useSignalTracking()
  useEffect(() => {
    trackPageView({ handle: handle, category: category })
  }, [trackPageView, handle, category])
}
