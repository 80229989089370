import styled from 'styled-components'

import {
  ResponsiveImage as LMFResponsiveImage,
  TypeStyle,
  fromMd,
} from '@syconium/little-miss-figgy'

export const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 50px;

  ${fromMd} {
    height: unset;
    padding-top: 78px;
  }
`

export const ResponsiveImage = styled(LMFResponsiveImage)`
  height: 75px;
  margin: auto;
  max-width: 800px;

  ${fromMd} {
    height: 160px;
  }

  .rtl & {
    transform: scaleX(-1);
  }
`

export const Heading = styled(TypeStyle.HeadlineExtraLarge)`
  margin-top: 20px;
  text-align: center;

  ${fromMd} {
    font-size: 36px;
  }
`

export const SubHeading = styled(TypeStyle.BodyLarge)`
  font-weight: normal;
  margin-top: 10px;
  text-align: center;
`

export const LinksWrapper = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  padding: 0 15px;
  gap: 20px;

  ${fromMd} {
    flex-direction: row;
    align-items: center;
  }
`

export const RecommendationsWrapper = styled.div`
  margin: 20px -9px 60px;

  > * + * {
    margin-top: 20px;
  }

  ${fromMd} {
    margin: 60px 0 110px 0;

    > * + * {
      margin-top: 42px;
    }
  }
`
