import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import {
  SupportedLanguageGroupIdentifier,
  SupportedLocaleIdentifier,
  retrieveLanguageRegionPairs,
} from '@syconium/weeping-figs'

import { useFixturesContext } from '../../brunswick/containers/fixtures'
import { LocalizationContainer } from '../../brunswick/containers/localization/LocalizationContainer'

type HreflangLink = {
  rel: 'alternate'
  hreflang: SupportedLocaleIdentifier | SupportedLanguageGroupIdentifier | 'x-default'
  href: string
}

export function useLocalizedMeta() {
  const { locale, region, languageGroup } = LocalizationContainer.useContainer()
  const { asPath } = useRouter()
  const rel = 'alternate'

  const {
    globals: {
      canonicalBaseUrl: { home },
    },
  } = useFixturesContext()
  const languageRegionPairs = retrieveLanguageRegionPairs()
  const homeNoTrailingSlash = home.replace(/\/$/, '') // remove trailing slash

  const getAlternateLinks = useCallback(
    (canonicalUrl?: string) => {
      let path = asPath
      if (canonicalUrl) path = canonicalUrl.split(homeNoTrailingSlash)[1] ?? ''
      const pathNoTrailingSlash = path.replace(/\/$/, '')
      const links: HreflangLink[] = [
        {
          rel: rel,
          hreflang: 'x-default',
          href: `${homeNoTrailingSlash}${path}`,
        },
      ]

      languageRegionPairs.forEach((locale: SupportedLocaleIdentifier) => {
        const href = `${home}${locale}${pathNoTrailingSlash}` // don't use slash for int'l
        const localeParts = locale.split('-')

        // only add alternates for the same region
        if (localeParts[1] === region.id) {
          links.push(
            // full locale as `hreflang`, `en-US` or `es-US` for example
            {
              rel: rel,
              hreflang: locale,
              href: href,
            },
            // lang-key only as `hreflang`, `en` or `es` for example
            {
              rel: rel,
              hreflang: localeParts[0] as SupportedLanguageGroupIdentifier,
              href: href,
            }
          )
        }
      })
      return links
    },
    [asPath, home, homeNoTrailingSlash, languageRegionPairs, region.id]
  )

  const getLocalizedCanonicalUrl = useCallback(
    (canonicalUrl: string | undefined) => {
      if (!canonicalUrl) return canonicalUrl
      const homeNoTrailingSlash = home.replace(/\/$/, '') // remove trailing slash
      const path = (canonicalUrl.split(homeNoTrailingSlash)[1] ?? asPath).replace(/\/$/, '') // remove trailing slash
      return `${home}${languageGroup}-${region.id}${path}`
    },
    [asPath, home, languageGroup, region.id]
  )

  const formattedLocale = useMemo(() => {
    return locale.replace('-', '_')
  }, [locale])

  return {
    getAlternateLinks,
    getLocalizedCanonicalUrl,
    formattedLocale,
  }
}
