import Head from 'next/head'
import { FC } from 'react'

import { Button } from '@syconium/little-miss-figgy'
import { useLocalizedMeta } from '@syconium/magnolia/src/components/SeoMeta/useLocalizedMeta'

import { MaybeSpaLinkWrapper } from '../../../../lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { ErrorLink } from '../../../containers/fixtures/IFixtures'

import { Container, Heading, LinksWrapper, ResponsiveImage, SubHeading } from './styles'

export const ErrorView: FC<{
  canonicalUrl: string
  heading: string
  imageAlt: string
  imageSrc: string
  links: readonly ErrorLink[]
  subheading: string
  children?: React.ReactNode
}> = ({ canonicalUrl, children, imageAlt, imageSrc, links, subheading, heading }) => {
  const { getAlternateLinks, getLocalizedCanonicalUrl } = useLocalizedMeta()

  return (
    <>
      <Head>
        <meta name='robots' content='noindex' key='robots' />
        <link rel='canonical' href={getLocalizedCanonicalUrl(canonicalUrl)} key='canonical' />
        {getAlternateLinks(canonicalUrl).map((item, index) => {
          return (
            <link
              rel={item.rel}
              hrefLang={item.hreflang}
              href={item.href}
              key={`alternate-${index}`}
            />
          )
        })}
      </Head>

      <Container data-testid='not-found-page'>
        <ResponsiveImage
          src={imageSrc}
          alt={imageAlt}
          aspectRatios={{
            sm: null,
            md: null,
          }}
          loading='default'
          widths={{ unit: 'vw', sm: 100, md: 50 }}
        />
        <Heading asTag='h1'>{heading}</Heading>

        <SubHeading>{subheading}</SubHeading>

        <LinksWrapper>
          {links.map((link, index) => (
            <MaybeSpaLinkWrapper key={index} href={link.href}>
              <Button as='a' href={link.href}>
                {link.title}
              </Button>
            </MaybeSpaLinkWrapper>
          ))}
        </LinksWrapper>

        {children}
      </Container>
    </>
  )
}
