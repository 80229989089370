import { FC } from 'react'

import { ResponsiveImage } from '@syconium/little-miss-figgy'
import { PriceDisplay } from '@syconium/magnolia/src/brunswick/components/PriceDisplay'
import { ITrackEvent } from '@syconium/magnolia/src/lib/analytics/IProps'

import { CarouselProductSummary } from '../../../../types/figs'

import { Body, ColorCount, ColorName, Colors, PriceDisplayWrap, Title } from './styles'

export const Template: FC<{
  aspectRatio: number
  colorCountText: string
  heapCategory?: string
  heapAction?: string
  heapLabel?: string | null
  heapValue?: string | number
  heapTrackSwipe?: boolean
  hideColors?: boolean
  hidePrice?: boolean
  product: CarouselProductSummary
  trackEvent: ITrackEvent
  visibleTilesDesktop: number
  visibleTilesMobile: number
}> = ({
  aspectRatio,
  colorCountText,
  hideColors,
  hidePrice,
  product,
  trackEvent,
  visibleTilesDesktop,
  visibleTilesMobile,
}) => {
  /**
   * TODO: We need to clean up the fact these can be undefined....... images
   * for a product should not be allowed to be undefined, handling that issue
   * this deep in the render tree is not the place for it.
   */
  const imageSrc = product.image
  return (
    <Body {...trackEvent}>
      {imageSrc ? (
        <ResponsiveImage
          alt={product.title}
          loading='lazy'
          src={imageSrc}
          aspectRatios={{
            sm: aspectRatio,
            md: aspectRatio,
          }}
          widths={{
            unit: 'vw',
            sm: 100 / visibleTilesMobile,
            md: 100 / visibleTilesDesktop,
          }}
        />
      ) : null}
      <Title>{product.title}</Title>
      {!hideColors && (
        <Colors>
          {product.colorRawName !== 'No Color' && <ColorName>{product.colorDisplayName}</ColorName>}
          <ColorCount>
            {product.colorCount} {colorCountText}
          </ColorCount>
        </Colors>
      )}
      {!hidePrice && (
        <PriceDisplayWrap>
          <PriceDisplay
            discountPrice={product.discountPrice}
            price={product.price}
            priceRange={product.priceRange}
            displayDiscountAsValue={false}
          />
        </PriceDisplayWrap>
      )}
    </Body>
  )
}
