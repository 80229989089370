import styled from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'

export const RecommendationsWrapper = styled.div`
  margin: 20px -9px 60px;

  > * + * {
    margin-top: 20px;
  }

  ${fromMd} {
    margin: 60px 0 110px 0;

    > * + * {
      margin-top: 42px;
    }
  }
`
