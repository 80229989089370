import * as React from 'react'

export interface PageSectionContextValue {
  pageSectionIndex: number
}

const initialValue: PageSectionContextValue = {
  pageSectionIndex: -1,
}

export const PageSectionContext = React.createContext<PageSectionContextValue | undefined>(
  initialValue
)

export const usePageSectionContext = (): PageSectionContextValue => {
  const pageSectionContext = React.useContext<PageSectionContextValue | undefined>(
    PageSectionContext
  )
  return pageSectionContext ?? initialValue
}
