import { stringifyUrl } from 'query-string'
import { useCallback, useMemo } from 'react'

import { Skeleton, Spacer } from '@syconium/little-miss-figgy'

import { NostoPlacementId } from '../../../app/_components/chrome/scripts/NostoScript.client'
import { useNostoRecos } from '../../../lib/hooks/useNostoRecos'
import { useConfigContext } from '../../containers/config'
import { ProductCarousel } from '../ProductCarousel'
import { ProductCarouselSkeleton } from '../ProductCarousel/Skeleton/ProductCarouselSkeleton'

import { Body, Title } from './styles'

import type { CSSProperties } from 'react'

export interface NostoProductRecosProps {
  analyticsContext?: {
    category?: string
  }
  fadeIn?: boolean
  nostoPlacementId: NostoPlacementId
  /**
   * Optionally override the Nosto-provided recommendation title.
   * Pass a string literal as a simple replacement, or pass a function that is
   * given the Nosto-provided recommendation title and returns a transformed
   * title.
   */
  title?: string | ((nostoTitle: string | undefined) => string | undefined)
  titleAlignmentDesktop?: CSSProperties['textAlign']
}

export const NostoProductRecos = ({
  analyticsContext,
  fadeIn,
  nostoPlacementId,
  title: titleOverride,
  titleAlignmentDesktop,
}: NostoProductRecosProps) => {
  const { placementTitle, productSummaries, resultId, status } = useNostoRecos({
    nostoPlacementId,
    productCountLimit: 8,
  })
  const { isPlain } = useConfigContext()

  const title = useMemo<string | undefined>(() => {
    return typeof titleOverride === 'function'
      ? titleOverride(placementTitle)
      : titleOverride ?? placementTitle
  }, [placementTitle, titleOverride])

  const augmentTileLinkPath = useCallback(
    (path: string) => {
      return stringifyUrl({
        url: path,
        query: { nosto: resultId, plain: isPlain || undefined },
      })
    },
    [isPlain, resultId]
  )

  if (status === 'rejected' || (status === 'resolved' && productSummaries.length === 0)) return null

  return (
    <Body fadeIn={!!fadeIn}>
      {status === 'pending' ? (
        <>
          <Skeleton height='42px' maxWidth='225px' />
          <Spacer height={24} />
          <ProductCarouselSkeleton fullBleed hideSectionSideGaps />
        </>
      ) : (
        <>
          {title ? (
            <Title textAlignDesktop={titleAlignmentDesktop} asTag='h2'>
              {title}
            </Title>
          ) : null}
          <ProductCarousel
            analyticsContext={analyticsContext}
            augmentTileLinkPath={augmentTileLinkPath}
            fullBleed
            hideSectionSideGaps
            products={productSummaries}
          />
        </>
      )}
    </Body>
  )
}

export default NostoProductRecos
